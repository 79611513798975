import React from "react"
import IconButton from "@material-ui/core/IconButton"
import FacebookIcon from "@material-ui/icons/Facebook"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import InstagramIcon from "@material-ui/icons/Instagram"
import EmailIcon from "@material-ui/icons/Email"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import { scroller } from "react-scroll"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import "./Footer.scss"

const emailAddress = "info@inappsystems.com"

const scrollToTop = scrollToId => {
  scroller.scrollTo(scrollToId, {
    duration: 1400,
    delay: 0,
    smooth: "easeInOutQuart",
  })
}

const Footer = ({ scrollToId }) => {
  const isMobile = useMediaQuery("(max-width:768px)")

  const handleSocialIconClick = platform => {
    trackCustomEvent({
      category: "Social icon click",
      action: `Click On ${platform}`,
    })
  }

  return (
    <footer className="Footer">
      <div className="Footer__Container">
        <div className="Footer__Container__Contacts">
          <p className="Footer__Container__Contacts__Title">Get In Touch</p>
          <p className="Footer__Container__Contacts__Text">{emailAddress}</p>
          <p className="Footer__Container__Contacts__Text">+374 41 014 001</p>
          <p className="Footer__Container__Contacts__Text">Yerevan, Armenia</p>
          <div className="Footer__Container__Contacts__Icons">
            <IconButton
              className="Footer__Container__Contacts__Icons__IconButton"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/InApp.Systems/"
              onClick={() => handleSocialIconClick("Facebook")}
            >
              <FacebookIcon className="Footer__Container__Contacts__Icons__IconButton__Icon" />
            </IconButton>
            <IconButton
              className="Footer__Container__Contacts__Icons__IconButton"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/inapp-systems/"
              onClick={() => handleSocialIconClick("LinkedIn")}
            >
              <LinkedInIcon className="Footer__Container__Contacts__Icons__IconButton__Icon" />
            </IconButton>
            <IconButton
              className="Footer__Container__Contacts__Icons__IconButton"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/inappsystems/"
              onClick={() => handleSocialIconClick("Instagram")}
            >
              <InstagramIcon className="Footer__Container__Contacts__Icons__IconButton__Icon" />
            </IconButton>
            <IconButton
              className="Footer__Container__Contacts__Icons__IconButton"
              href={"mailto:" + emailAddress}
              onClick={() => handleSocialIconClick("Email")}
            >
              <EmailIcon className="Footer__Container__Contacts__Icons__IconButton__Icon" />
            </IconButton>
          </div>
          {isMobile && (
            <p className="Footer__Container__Contacts__Copyright">
              © 2019-2020 InApp Systems. All rights reserved.
            </p>
          )}
        </div>
        <div className="Footer__Container__CopyrightWrapper">
          <IconButton
            className="Footer__Container__CopyrightWrapper__IconButton"
            onClick={() => scrollToTop(scrollToId)}
          >
            <ArrowUpwardIcon className="Footer__Container__CopyrightWrapper__IconButton__Icon" />
          </IconButton>
          {!isMobile && (
            <p className="Footer__Container__CopyrightWrapper__Copyright">
              © 2019-2020 InApp Systems. All rights reserved.
            </p>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
