import React from "react"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"

import "./Input.scss"

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root fieldset": {
      borderColor: "black",
      transition: "0.3s",
    },

    "&:hover": {
      "& .MuiOutlinedInput-root fieldset": {
        // marginRight: -12,
      },
    },

    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },

    "& .MuiOutlinedInput-root": {
      height: "50px",
      borderRadius: 0,

      "&.Mui-focused fieldset": {
        borderColor: "black",
        borderWidth: 1,
      },
      "&.Mui-error fieldset": {
        borderColor: "red",
      },
    },
  },
})(TextField)

const Input = ({ label, multiline, margin, ...inputProps }) => {
  return (
    <div className="CustomInputField" style={{ margin: margin }}>
      <CustomTextField
        variant="outlined"
        placeholder={label}
        multiline={multiline}
        rows={multiline && 2}
        rowsMax={multiline && 7}
        FormHelperTextProps={{ className: "CustomInputField__InputHelper" }}
        InputProps={{ className: "CustomInputField__InputLabel" }}
        {...inputProps}
      />
    </div>
  )
}

export default Input
