import React from "react"

import "./Arrow.scss"

const Arrow = ({ onClick, color = "white" }) => {
  return (
    <div
      className="Arrow"
      role="button"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
    >
      <div className="Arrow__Icon" style={{ borderColor: color }}></div>
    </div>
  )
}

export default Arrow
