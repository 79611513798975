import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import "./LandingContentItem.scss"

const LandingContentItem = ({
  to,
  isMorePostsClick,
  mainImage,
  title,
  shortText,
  author,
  time,
}) => {
  const [hover, setHover] = useState(false)

  const handleMorePostsClick = () => {
    trackCustomEvent({
      category: "More posts click",
      action: `Click On One of More Posts`,
    })
  }

  const handleItemClick = () => {
    if (isMorePostsClick) {
      handleMorePostsClick()
    }
  }

  return (
    <div className="LandingContentItem">
      <Link
        to={to}
        onClick={handleItemClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div className="LandingContentItem__MainImageWrapper">
          <Img
            className="LandingContentItem__MainImageWrapper__Image"
            style={{ opacity: hover && 0.85, transform: hover && "scale(1.0)" }}
            fluid={mainImage.childImageSharp.fluid}
            alt={title}
          />
        </div>

        <div
          className="LandingContentItem__Texts"
          style={{ paddingTop: hover && 36 }}
        >
          <h2
            className="LandingContentItem__Texts__Title"
            style={{ color: hover && "#d50000" }}
          >
            {title}
          </h2>
          <p className="LandingContentItem__Texts__ShortText">{shortText}</p>
        </div>
        <div
          className="LandingContentItem__Details"
          style={{ padding: hover && "18px 24px 18px 24px" }}
        >
          <p className="LandingContentItem__Details__Author">{author}</p>
          <p className="LandingContentItem__Details__Time">{time}</p>
        </div>
      </Link>
    </div>
  )
}

export default LandingContentItem
