import React, { useState } from "react"
import MUIButton from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

import "./Button.scss"

const redColor = "#d50000"
const alphaRedColor = "#d50000a6"
const greyColor = "#e9e9e9"
const blackColor = "#000000"
const whiteColor = "#ffffff"

const Button = ({
  label,
  onClick,
  width,
  maxWidth,
  height,
  fontSize,
  fontWeight,
  margin,
  padding,
  disabled,
  inverseColors = false,
  loading = false,
  ...inputProps
}) => {
  const [isHover, setIsHover] = useState(false)

  const color = !inverseColors ? blackColor : whiteColor
  const hoverColor = !inverseColors ? whiteColor : whiteColor
  const backgroundColor = !inverseColors ? greyColor : redColor
  const hoverBackgroundColor = !inverseColors ? redColor : alphaRedColor

  return (
    <MUIButton
      className="CustomButton"
      variant="outlined"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
      style={{
        color: !isHover ? color : hoverColor,
        backgroundColor: !isHover ? backgroundColor : hoverBackgroundColor,
        width: width,
        maxWidth: maxWidth,
        height: height,
        fontSize: fontSize,
        fontWeight: fontWeight,
        padding: padding,
        margin: margin,
      }}
      disabled={loading || disabled}
      {...inputProps}
    >
      {!loading ? (
        label
      ) : (
        <CircularProgress className="CustomButton__Progressbar" />
      )}
    </MUIButton>
  )
}

export default Button
