import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"

import "./layout.scss"

export default ({ children }) => (
  <ParallaxProvider>
    <div>{children}</div>
  </ParallaxProvider>
)
