import React, { useState, useEffect } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import MUIButton from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"

import { EmailValid } from "../../utils/paramValidator"
import { Input, Button } from "../atoms"

import "./Subscribe.scss"

const successMessage = (
  <DialogContentText
    style={{
      fontSize: "1.4rem",
      color: "#222222",
    }}
  >
    Thank you for subscribing to our newsletter. We hope you will find great
    value in the emails that you will receive.
    <br />
    <br />
    Team InApp
  </DialogContentText>
)

const failureMessage = (
  <DialogContentText
    style={{
      fontSize: "1.4rem",
      color: "#222222",
    }}
  >
    Unfortunately something went wrong. Please try again later or contact us via
    info@inappsystems.com or through other mediums.
    <br />
    <br />
    Team InApp
  </DialogContentText>
)

const CustomForm = ({ status, onValidated }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [email, setEmail] = useState("")
  const [emailValid, setEmailValid] = useState(true)
  const isMobile = useMediaQuery("(max-width:768px)")

  useEffect(() => {
    if (status === "success" || status === "error") {
      setDialogOpen(true)
    }
  }, [status])

  const handleSubscription = () => {
    if (isEmailValid()) {
      onValidated({
        EMAIL: email,
      })
      setEmail("")
    } else {
      setEmailValid(false)
    }
  }

  const handleEmailChange = event => {
    const value = event.target.value

    setEmail(value)

    if (!emailValid) {
      setEmailValid(isEmailValid(value))
    }
  }

  const handleEmailFocusOut = event => {
    setEmailValid(isEmailValid(event.target.value))
  }

  const isEmailValid = paramEmail => {
    if (!paramEmail) {
      paramEmail = email
    }

    return EmailValid(paramEmail)
  }

  const getDialogTitle = () => {
    if (status === "success") {
      return (
        <Typography className="CustomSubscribeForm__Dialog__Title">
          Thanks for Subscribing!
        </Typography>
      )
    } else if (status === "error") {
      return (
        <Typography className="CustomSubscribeForm__Dialog__Title">
          <span role="img" aria-label="emoji">
            Ooops 😕
          </span>
        </Typography>
      )
    }
  }

  const getDialogContent = () => {
    if (status === "success") {
      return successMessage
    } else if (status === "error") {
      return failureMessage
    }
  }

  return (
    <>
      <Input
        label="Enter your email address ..."
        type="email"
        name="email"
        value={email}
        onChange={event => handleEmailChange(event)}
        onBlur={event => handleEmailFocusOut(event)}
        error={!emailValid}
        helperText={!emailValid && "Please enter your email address"}
        margin="0 8px 0 0"
      />
      <Button
        label="Subscribe"
        onClick={handleSubscription}
        inverseColors={true}
        fontSize={!isMobile ? "1.4rem" : "1.3rem"}
        width={!isMobile ? "180px" : "160px"}
        height="50px"
        fontWeight="400"
      />
      <Dialog
        maxWidth="sm"
        className="CustomSubscribeForm__Dialog"
        open={dialogOpen}
      >
        <DialogTitle>{getDialogTitle()}</DialogTitle>
        <DialogContent className="Contacts__Content__Dialog__Content">
          {getDialogContent()}
        </DialogContent>
        <DialogActions>
          <MUIButton
            className="CustomSubscribeForm__Dialog__CloseButton"
            onClick={() => setDialogOpen(false)}
          >
            <Typography className="CustomSubscribeForm__Dialog__CloseButton__Text">
              Close
            </Typography>
          </MUIButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const Subscribe = () => {
  return (
    <div className="Subscribe">
      <div className="Subscribe__Wrapper">
        <p className="Subscribe__Wrapper__Title">
          Subscribe To The InApp Newsletter
        </p>
        <p className="Subscribe__Wrapper__Subtitle">
          Join our newsletter for Free Now and stay up to date with us by
          receiving interesting tech related emails.
        </p>
        <div className="Subscribe__Wrapper__Actions">
          <MailchimpSubscribe
            url={process.env.GATSBY_MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default Subscribe
