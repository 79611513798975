import React from "react"
import MUIButton from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

import "./OutlineButton.scss"

const OutlineButton = ({
  label,
  onClick,
  width,
  maxWidth,
  height,
  fontSize,
  fontWeight,
  margin,
  padding,
  disabled,
  loading = false,
  ...inputProps
}) => {
  return (
    <MUIButton
      className="CustomOutlineButton"
      variant="outlined"
      onClick={onClick}
      style={{
        width: width,
        maxWidth: maxWidth,
        height: height,
        fontSize: fontSize,
        fontWeight: fontWeight,
        padding: padding,
        margin: margin,
      }}
      disabled={loading || disabled}
      {...inputProps}
    >
      {!loading ? (
        label
      ) : (
        <CircularProgress className="CustomOutlineButton__Progressbar" />
      )}
    </MUIButton>
  )
}

export default OutlineButton
